@font-face {
  font-family: myFirstFont;
  src: url(../fonts/RENFREWN.TTF)
}

.prods {
    text-align: center;
    font-family: myFirstFont;
}

  .card-block{
    padding-left:50px;
    padding-right:50px;
  }
  .item-card{
    transition:0.5s;
    cursor:pointer;
  }
  .item-card-title{ 
    text-align: center; 
    font-size:25px;
    transition:1s;
    cursor:pointer;
  }
  .item-card-title i{  
    font-size:15px;
    transition:1s;
    cursor:pointer;
    color:#ffa710
  }
  .card-title i:hover{
    transform: scale(1.25) rotate(100deg); 
    color:#18d4ca;
    
  }
  .card:hover{
    transform: scale(1.05);
    box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
  }
  .card-text{
    height:80px;  
  }
  
  .card::before, .card::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform .3s ease-out 0s;
    background: rgba(255, 255, 255, 0.1);
    content: '';
    pointer-events: none;
  }
  .card::before {
    transform-origin: left top;
  }
  .card::after {
    transform-origin: right bottom;
  }
  .card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
    transform: scale3d(1, 1, 1);
  }

  @media only screen and (max-width: 600px) {
    .prods {
      font-size: 35px;
    }
  }