.footer_ {
    font-size: 25px;
    border: 2px solid black;
    background-color: black;
    color: white;
    font-family: 'Lobster Two', cursive;
}

.name {
   display: block;
   margin-left: auto;
   margin-right: auto;
}
@media only screen and (max-width: 600px) {
    .footer_ {
        font-size: 14px;
    }
}