@font-face {
    font-family: myFirstFont;
    src: url(../fonts/RENFREWN.TTF)
}

.about_us {
    font-family: myFirstFont;
    font-weight: 100;
}

.deal_img {
    width: 180px;
    height: 180px;
    border-radius: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.unbrako_img {
    width: 200px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.apl_img {
    width: 300px;
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.about_content {
    padding-left: 15px;
    font-size: 20px;

}