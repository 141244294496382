.h1-responsive {
    font-family: 'Big Shoulders Stencil Text', cursive;
    font-size: 70px;
    color:white;
} 

.first_img {
    margin-top:85px;
    width:100%;
    height: 677px;
    object-fit: fill;
}
.sec_img {
    margin-top:85px;
    width:100%;
    height: 677px;
}

.third_img {
    margin-top:85px;
    width:100%;
    height: 677px;
}

.fourth_img {
    margin-top:85px;
    width:100%;
    height: 677px;
}

.fifth_img {
    margin-top:85px;
    width:100%;
    height: 677px;
}

.carousel-control-next-icon{
    display: none;
} 
.carousel-control-prev-icon {
    display: none;
}

.text_1 {
    margin-bottom: 320px;
}

.text_2 {
    background-color:lightblue;
    margin-bottom: 300px;
}

.text_3 {
    background-color:lightblue;
    margin-bottom: 400px;
}

.text_4 {
    margin-bottom: 300px;
    background-color:lightblue;
}

.text_5 {
    margin-bottom: 100px;
    background-color:lightblue;
}

@media only screen and (max-width: 600px) {
    .first_img {
        margin-top:70px;
        width:100%;
        height: 645px;
    }
    .sec_img {
        margin-top:70px;
        width:100%;
        height: 645px;
    }    
    .third_img {
        margin-top:70px;
        width:100%;
        height: 645px;
    }
    .fourth_img {
        margin-top:70px;
        width:100%;
        height: 645px;
    }
    .fifth_img {
        margin-top:70px;
        width:100%;
        height: 645px;
    }
    .h1-responsive {
        font-size: 45px;
    }

    .text_1 {
        margin-bottom: 290px;
    }

    .text_2 {
        margin-bottom: 250px;
    }

    .text_3 {
        margin-bottom: 360px;
    }

    .text_4 {
        margin-bottom: 250px;
    }
    .text_5 {
        margin-bottom: 100px;
    }
}