.header{
    background-color:black;
    height: 85px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
     transition: all 0.4s ease;
    
}

.header_sticky {
    height: 75px;
    text-align: center;
    background-color: rgba(10,10,10,0.83);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.logo_ {
    width: 60px;
    height: 65px;
}

.logo_sticky {
    
    width: 50px;
    height: 55px;
}

@font-face {
    font-family: myFirstFont;
    src: url(../fonts/ARISTON.TTF)
}

.heading_name {
    color: white;
    /* font-family: 'Lobster Two', cursive; */
    font-size:210%;
    font-family: myFirstFont;
}

.heading_name_sticky {
    color: white;
    font-size:150%;
}

.btn {
    color: white;
}

.nav-item > .active {
    border-bottom: 1px solid white;
}
.nav-item {
    color: white;
    padding-right: 15px;
    /* font-family: 'Lobster Two', cursive; */
    font-size: 18px;
    font-family: myFirstFont;
}
.nav-item:hover {
    color: grey;
}
.navbar-expand-md .navbar-nav .nav-link {
    color: white;
}

.navbar-expand-md .navbar-nav .nav-link:hover {
    color:grey;
}

@media only screen and (max-width: 600px) {
    .logo_ {
        margin-left: auto;
        width: 45px;
        height: 50px;
    }

    .logo_sticky {
    
        width: 35px;
        height: 40px;
    }

    .header{
        background-color:black;
        height: 70px;
        -webkit-transition: all 0.4s ease;
         transition: all 0.4s ease;
    }

    .header_sticky {
        height: 60px;
        text-align: center;
        background-color: rgba(10,10,10,0.83);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .heading_name {
        font-size:120%;
    }

    .heading_name_sticky {
        font-size:110%;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        color:white;
    }
    
    .navbar-expand-md .navbar-nav .nav-link:hover {
        color:grey;
    }
    .nav-item {
        font-size: 30px;
    }
}

@media only screen and (max-width: 770px) {
    .nav-item {
        font-size: 30px;
        font-style: italic;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        color:white;
    }
    
    .navbar-expand-md .navbar-nav .nav-link:hover {
        color:grey;
    }

    .colla_back {
        background-color: rgba(10,10,10,0.63);
    }
}